.changelog {
  display: flex;
  flex-direction: column;
  align-items: center; /* 전체 FAQ 내용을 가운데 정렬 */
  padding: 20px;
  padding-top: 80px;
  box-sizing: border-box;
}

.changelogTitle {
  margin-bottom: 40px;
  text-align: left;
}

.changelogContent {
  min-width: 40vw;
}

.changelogContentTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: "Fira Code";
}

.changelogContentTitle > a {
  margin-left: 10px;
  color: grey;
}
