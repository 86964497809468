body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url(https://cdn.jsdelivr.net/npm/firacode@6.2.0/distr/fira_code.css);

code {
  font-family: "Fira Code", monospace;
}

@supports (font-variation-settings: normal) {
  code {
    font-family: "Fira Code VF", monospace;
  }
}
