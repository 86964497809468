.navigator {
  font-family: "Fira Code";
  font-size: 16px;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  align-items: center; /* 세로 가운데 */
  justify-content: space-between; /* 가로 가운데 */
  box-sizing: border-box;

  background-color: white;
}

.rightMenu > a {
  margin-left: 20px;
}

.navigatorTitle {
  font-weight: bold;
}

.navigator a {
  text-decoration: none;
  color: inherit;
}

.activeLink {
  font-weight: bold;
}
