.faq {
  display: flex;
  flex-direction: column;
  align-items: center; /* 전체 FAQ 내용을 가운데 정렬 */
  padding: 20px;
  padding-top: 80px;
  box-sizing: border-box;
}

.faq img {
  height: 144px;
}

.faqTitle {
  margin-bottom: 40px;
  text-align: left;
}
