.discordButton {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #5865f2;
  width: 280px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.discordButton > p {
  font-family: "Fira Code";
  font-size: 20px;
  margin-left: 20px;
}

.discordButton:active,
.discordButton:hover,
.discordButton:focus {
  background-color: #414aa6;
}
